
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="bottomTaskType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">合同列表</div>
          <div v-if="bottomTaskType!==1" :class="bottomTaskType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">合同详情</div>
        </div>

        <component :is="currentComponent + 'Component'" ref="bottom" :id.sync="id" @on-showDetail="showDetail" @on-setting="setting" />
    </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    listComponent: (resolve) => {
      require(['./bottom/List'], resolve)
    },
    detailComponent: (resolve) => {
      require(['./bottom/Detail'], resolve)
    }
  },
  data () {
    return {
      id: 0,
      bottomTaskType: 1,
      currentComponent: 'list'
    }
  },
  created () {
    // this.setShowRightBlock(true)
    // this.setRightComponent('rightList')
  },
  methods: {
    handleChangeTag (val) {
      this.bottomTaskType = val
      if (val === 1) {
        this.currentComponent = 'list'
      } else if (val === 2) {
        this.currentComponent = 'detail'
      }
    },
    reload () {
      this.$refs.bottom.reload()
    },
    showDetail (id) {
      this.id = id
      this.handleChangeTag(2)
    },
    setting (id) {
      this.$emit('on-showLeft', id)
    }
  }
}
</script>
